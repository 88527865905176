import React, { useState } from 'react';
import promo from "../../../src/video/rf-dance-salsa-weds.mp4";
import Hero from "../../components/hero";
import Contact from "../../components/contact";
import Layout from "../../components/layout-default";
import Breadcrumbs from "../../components/breadcrumbs";
import TeamImg from "../../images/team.jpeg";
import blogs from '../../components/BlogsData';
import { Link } from "gatsby";
import "./index.scss";
const crumbs = [
  {
    label: "Home",
    link: "",
  },
  {
    label: "Blog",
    link: "blog",
  },
];

const SalsaWednesdays = () => {
  const listOfBlogs = blogs.sort((a, b) => new Date(b.date) - new Date(a.date));
  const blogsPerLoad = 6;
  const [displayCount, setDisplayCount] = useState(blogsPerLoad);
  // Handler for the Load More button
  const loadMoreBlogs = () => {
    setDisplayCount(prevCount => prevCount + blogsPerLoad);
  };

  return (
    <>
      <Layout
        title="Explore Dance Blog | RF Dance Studio - Orange County"
        description="Explore a treasure trove of dance insights, tips, and stories on our blog. Join RF Dance Studio in Orange County and stay in step with the world of dance."
        pathname="blog/"
        img={TeamImg}
        className="blogs_layout"
      >
        <Hero
          titleRed="Salsa"
          titleWhite="Wednesdays"
          subtitle="Orange County's Wednesday night salsa class"
          promo={promo}
          secondaryBtnTxt="Sign up online"
          secondaryBtnLink="/classes"
          secondaryBtnType="link"
        />
        <div className="content-block">
          <div className="container">
            <Breadcrumbs crumbs={crumbs} />
            {/* <BlogPagination /> */}
            <div className="inner_blogs">
              <div className='blogs_content'>
              {listOfBlogs.slice(0, displayCount).map((blog, index) => (
                  <div key={"col_" + blog.id} className="column_blog">
                    <Link to={blog.path}>
                      <div className="mainDiv-img">
                        <div className="imgDiv">
                          <img src={blog.image} alt={blog.title} />
                        </div>
                        <div className="h3-div">
                          <h3 className="h3-content">{blog.title}</h3>
                          <p className="blogPosted">Posted on - {blog.date}</p>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
                </div>
                 {displayCount < blogs.length && (
                      <button onClick={loadMoreBlogs}>Load More</button>
                  )}
            </div>
          </div>
        </div>
        <Contact />
      </Layout>
    </>
  )
};

export default SalsaWednesdays;